<template>
  <section class="product padding-top" id="product">
    <Title class="fadeDown">{{ translate.PAGES.productTitle }}</Title>
    <div class="container product__container">
      <p class="product__description margin-top fadeDown">
        {{ translate.PRODUCT.description }}
      </p>
      <!-- <ProductReview></ProductReview> -->
      <div class="product__grid margin-top">
        <ProductCard
          v-for="(card, index) in cardsList"
          :key="index"
          :num="index + 1"
          :type="card"
        ></ProductCard>
      </div>
    </div>
  </section>
</template>

<script setup>
import Title from "@components/Title.vue";
import ProductCard from "@components/product/ProductCard.vue";
import ProductReview from "@components/product/ProductReview.vue";
import useTranslate from "@hooks/useTranslate";

const translate = useTranslate();

const cardsList = [
  "wireless",
  "flash-on",
  "functionality",
  "impact-resistance",
];
</script>

<style lang="scss" scoped>
.product {
  overflow: hidden;

  &__description {
    text-align: center;
    font-size: 24px;

    color: #ffffff;

    @media (max-width: 768px) {
      & {
        font-size: 18px;
      }
    }

    @media (max-width: 425px) {
      & {
        font-size: 14px;
      }
    }
  }

  &__image {
    width: 100%;
  }

  &__grid {
    $grid-layout-gap: 20px;
    $grid-column-count: 2;
    $grid-item--min-width: 240px;

    $gap-count: calc($grid-column-count - 1);
    $total-gap-width: calc($gap-count * $grid-layout-gap);
    $grid-item--max-width: calc((100% - $total-gap-width) / $grid-column-count);

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max($grid-item--min-width, $grid-item--max-width), 1fr)
    );
    grid-gap: $grid-layout-gap;
  }
}
</style>
