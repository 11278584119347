import { createRouter, createWebHistory } from "vue-router";
import BannerView from "@views/BannerView.vue";
import ProductView from "@views/ProductView.vue";
import AdvantagesView from "@views/ProductView.vue";
import ConfigurationsView from "@views/ProductView.vue";
import CharacteristicsView from "@views/ProductView.vue";
import FeedbackView from "@views/ProductView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: BannerView,
  },
  {
    path: "/product",
    name: "product",
    component: ProductView,
  },
  {
    path: "/advantages",
    name: "advantages",
    component: AdvantagesView,
  },
  {
    path: "/configurations",
    name: "configurations",
    component: ConfigurationsView,
  },
  {
    path: "/characteristics",
    name: "characteristics",
    component: CharacteristicsView,
  },
  {
    path: "/feedback",
    name: "feedback",
    component: FeedbackView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 90,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;
