<template>
  <h3 class="title">
    <slot></slot>
  </h3>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "../colors.scss";

.title {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  width: 100%;
  text-align: center;
  padding: 0 20px;

  color: #ffffff;

  &:before {
    content: "";
    display: block;
    position: absolute;
    max-width: 500px;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: $mainColor;
    transform: translateX(-50%);
    left: 50%;
    top: calc(100% + 6px);
  }

  @media (max-width: 768px) {
    & {
      font-size: 36px;
    }

    &:before {
      max-width: 400px;
    }
  }

  @media (max-width: 425px) {
    & {
      font-size: 24px;
    }

    &:before {
      max-width: 200px;
    }
  }
}
</style>
