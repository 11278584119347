<template>
  <section class="banner" id="home">
    <div class="container banner__container">
      <h1 class="banner__title bounceLeft t-animated" data-t-show="3">
        {{ translate.BANNER.title }}
      </h1>
      <p class="banner__subtitle fadeDown t-animated" data-t-show="4">
        {{ translate.BANNER.subtitle }}
      </p>
    </div>
  </section>
</template>

<script setup>
import useTranslate from "@hooks/useTranslate";

const translate = useTranslate();
</script>

<style lang="scss" scoped>
.banner {
  height: calc(100vh - 90px);
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("@assets/banner.jpg") 70% top;
  background-size: cover;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    height: 100%;
  }

  &__title {
    font-family: "Press Start 2P";
    font-style: normal;
    font-weight: 400;
    max-width: 10ch;
    width: 100%;
    font-size: 56px;
    line-height: 56px;
    margin-left: 50px;

    @media (max-width: 768px), (max-height: 768px) {
      font-size: 32px;
      margin-left: 0;
    }

    @media (max-width: 425px), (max-height: 425px) {
      text-align: center;
      max-width: 100%;
      font-size: 22px;
    }

    @media (max-height: 375px) {
      font-size: 18px;
    }

    color: #ffffff;
  }

  &__subtitle {
    font-family: "Vollkorn";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    margin-left: 50px;

    @media (max-width: 768px), (max-height: 768px) {
      margin-left: 0;
      font-size: 24px;
    }

    @media (max-width: 425px), (max-height: 425px) {
      text-align: center;
      max-width: 100%;
      font-size: 18px;
    }

    @media (max-height: 375px) {
      font-size: 16px;
    }

    color: #ffffff;
  }
}
</style>
