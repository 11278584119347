<template>
  <footer class="footer margin-top">
    <div class="footer__container container">
      <RouterLink :to="{ hash: '' }">
        <img class="logo fadeLeft" src="@assets/fasie-logo.png" alt="logo" />
      </RouterLink>
      <div class="footer__text-container">
        <p class="footer__text fadeIn">
          При поддержке фонда содействия инновациям
        </p>
        <p class="footer__text fadeIn">
          При поддержке в рамках федерального проекта «Платформа
          университетского технологического предпринимательства»
        </p>
      </div>
      <div class="footer__text-container">
        <p class="footer__text fadeIn">{{ translate.FOOTER.ogrn }}</p>
        <p class="footer__text fadeIn">{{ translate.FOOTER.inn }}</p>
        <p class="footer__text fadeIn">{{ translate.FOOTER.kpp }}</p>
      </div>
      <div class="footer__text-container">
        <p class="footer__text fadeIn">{{ translate.FOOTER.address }}</p>
        <p class="footer__text fadeIn">
          {{ translate.FOOTER.registrationDate }}
        </p>
      </div>
      <h6 class="footer__email">{{ translate.FOOTER.mail }}</h6>
    </div>
  </footer>
</template>

<script setup>
import useTranslate from "@hooks/useTranslate";

import { RouterLink } from "vue-router";
const translate = useTranslate();
</script>

<style lang="scss" scoped>
@import "../colors.scss";

.footer {
  width: 100%;
  min-height: 120px;
  background-color: #000;
  overflow: hidden;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 100%;

    @media (max-width: 1024px) {
      gap: 15px;
      flex-direction: column;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    width: 100%;
    gap: 15px;

    @media (max-width: 1024px) {
      max-width: unset;
      text-align: center;
    }
  }

  &__text {
    font-size: 14px;

    color: #ffffff;
  }

  &__email {
    font-family: "Press Start 2P";
    font-size: 16px;

    color: $mainColor;
  }
}
</style>
