import { createStore } from 'vuex'

import RU from '@directory/ru';
import EN from '@directory/en';

export default createStore({
  state: {
    language: "RU",
  },
  getters: {
    DIRECTORY: (state) => {
      return {
        RU: RU,
        EN: EN,
      }[state.language];
    },
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    changeLanguage({ commit, _, getters }) {
      window.localStorage.setItem("language", getters["DIRECTORY"].LANGUAGE_TO_CHANGE);
      commit("setLanguage", getters["DIRECTORY"].LANGUAGE_TO_CHANGE);
    },
    setLanguage({commit}, language) {
      commit("setLanguage", language);
    },
  }
})
