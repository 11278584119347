<template>
  <HeaderView></HeaderView>
  <MainView></MainView>
  <FooterView></FooterView>
</template>

<script setup>
import HeaderView from "@views/HeaderView.vue";
import MainView from "@views/MainView.vue";
import FooterView from "@views/FooterView.vue";

import Tu from "@assets/t-scroll.min.js";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const animatePage = () => {
  Tu.tScroll({
    "t-element": ".bounceLeft",
  });

  Tu.tScroll({
    "t-element": ".fadeIn",
  });

  Tu.tScroll({
    "t-element": ".fadeDown",
  });

  Tu.tScroll({
    "t-element": ".fadeLeft",
  });

  Tu.tScroll({
    "t-element": ".fadeRight",
  });

  Tu.tScroll({
    "t-element": ".slideDown",
  });
};

const sectionObserver = ref(null);

const sectionObserverHandler = (entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      const sectionId = entry.target.id;
      if (sectionId !== "home") {
        window.history.pushState({}, "", `/#${sectionId}`);
      } else {
        window.history.pushState({}, "", "/");
      }
    }
  }
};

const observeSections = () => {
  try {
    sectionObserver.value.disconnect();
  } catch (error) {}

  const options = {
    rootMargin: "0px",
    threshold: 0.5,
  };

  sectionObserver.value = new IntersectionObserver(
    sectionObserverHandler,
    options
  );

  const sections = document.querySelectorAll("section");
  sections.forEach((section) => {
    sectionObserver.value.observe(section);
  });
};

onMounted(() => {
  animatePage();

  const language = window.localStorage.getItem("language");

  if (language === "RU" || language === "EN") {
    store.dispatch("setLanguage", language);
  }

  observeSections();
});
</script>

<style lang="scss">
@import "./consts.scss";
@import "./colors.scss";
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  background-color: $backgroundColor;

  scrollbar-width: thin;
  scrollbar-color: #dee2e6 transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $mainColor;
    border-radius: 4px;
  }

  &:hover {
    scrollbar-color: $lightenColor transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $secondaryColor;
  }
  &::-webkit-scrollbar-thumb:active {
    background: scale-color($mainColor, $blackness: 30%);
  }
}

#app {
  background-color: $backgroundColor;
}

.container {
  max-width: 1300px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
}

.padding-top {
  padding-top: 50px;

  @media (max-width: 1024px), (max-height: 1024px) {
    padding-top: 40px;
  }

  @media (max-width: 768px), (max-height: 768px) {
    padding-top: 30px;
  }

  @media (max-width: 425px), (max-height: 425px) {
    padding-top: 20px;
  }
}

.margin-top {
  margin-top: 50px;

  @media (max-width: 1024px), (max-height: 1024px) {
    margin-top: 40px;
  }

  @media (max-width: 768px), (max-height: 768px) {
    margin-top: 30px;
  }

  @media (max-width: 425px), (max-height: 425px) {
    margin-top: 20px;
  }
}

.card {
  background-color: #1e1e1e;
  border-radius: 8px;
}

.swiper-button-next,
.swiper-button-prev {
  color: $mainColor;
}
</style>
