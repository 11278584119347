import { ref, computed } from 'vue';
import { useStore } from 'vuex';

/**
 * Хук useTranslate
 * @returns Объект справочника
 */
const useTranslate = () => {
  const store = useStore(); // Создаём store

  const directory = computed(() => store.getters["DIRECTORY"]);

  return directory;
};

export default useTranslate;