export default {
  LANGUAGE_TO_CHANGE: "RU",
  PAGES: {
    productTitle: "About product",
    advantagesTitle: "Advantages",
    configurationsTitle: "Configuration",
    // configurationsTitle: "Configurations",
    characteristicsTitle: "Characteristics",
    feedbackTitle: "Leave feedback",
  },
  BANNER: {
    title: "Be in the motion",
    subtitle: "to win",
  },
  PRODUCT: {
    description:
      "Modern elite sport needs continuous improvement of methods and technologies for building a training program in the training process, taking into account the individual characteristics of the athlete's body, based on taking into account his psychophysiological state and physical performance.",
    fieldTitle: [
      "Everything under control",
      "All-seeing eye",
      "Precision above anything else",
      "Space connection",
    ],
    fieldDescription: [
      "30 monitoring devices take real-time data from each player and monitor his physical condition and movement on the playing field.",
      "Monitoring devices transmit all information to the coaching staff for its analysis and decision making. The coach always has objective data about his team as a whole and about each athlete in particular.",
      "Beacons for real-time transmission of data to the tablet with a minimum chance of losing events and maximum control over the players.",
      "Building a heat map of movement, analyzing sprints, running at high speed, acceleration and other parameters, all thanks to the GPS GLONASS sensor in our devices.",
    ],
    card: {
      wireless: "Wireless data transfer",
      portable: "Portability",
      functionality: "Multifunctionality",
      "impact-resistance": "Impact resistance",
      "moisture-resistance": "Moisture resistance",
      "temperature-resistance": "Temperature resistance",
    },
  },
  ADVANTAGES: {
    soccer:
      "Heart under control - a heart rate monitor is an essential tool that will help you effectively assess the training load, build and track training plans, increase their effectiveness and maintain health.",
    hockey:
      "GPS (GLONASS) tracker - created to reveal the true potential of the team. NEW STANDARDS FOR PRECISION, EASY TO USE AND EFFICIENCY.",
    basketball:
      "Whether PRO - Heart rate monitor and GPS (GLONASS) teamed together to achieve maximum results Indoors or outdoors. Analytical materials, fast processing and solution of specific sports tasks, server-side data processing, all this is NanoFit Pro.",
  },
  CONFIGURATIONS: {
    // HR: {
    //   title: "NanoFit HR",
    //   options: [
    //     "30 ЧСС устройств",
    //     "30 ремешков для ЧСС устройств",
    //     "универсальная зарядная станция",
    //     "ПО для ЧСС устройств",
    //   ],
    // },
    PRO: {
      title: "NanoFit Pro",
      options: [
        "30 monitoring devices",
        "30 shirt-fronts OR 30 straps, depending on the consumer's preference",
        "6 beacons",
        "charging station",
        "software",
      ],
      // options: [
      //   "30 ЧСС устройств",
      //   "30 GPS ГЛОНАСС устройств",
      //   "30 ремешков для ЧСС устройств",
      //   "30 манишек GPS ГЛОНАСС устройств ЛИБО 30 универсальных устройств с ремешками и манишками",
      //   "универсальная зарядная станция",
      //   "общее ПО",
      // ],
    },
    // GPS: {
    //   title: "NanoFit GPS",
    //   options: [
    //     "30 GPS ГЛОНАСС устройств",
    //     "30 ремешков для GPS ГЛОНАСС устройств",
    //     "универсальная зарядная станция",
    //     "ПО для GPS ГЛОНАСС устройств",
    //   ],
    // },
    SUBSCRIPTION: {
      title: "NanoFit Space",
      subtitle: "optional software subscription",
      options: [
        "Extensive real-time data (up to 60 players)",
        "Ability to add notes during training",
        "Advanced analysis view for in-depth comparison of sample data",
        "Heat map for location analysis",
        "Powerful workout analysis and comparison",
        "Convenient team and player settings through sport profiles",
        "Easily access and share data by coaching staff across multiple teams",
        "Player position on a heat map or line graph",
      ],
    },
  },
  CHARACTERISTICS: {
    GPS: {
      title: "NanoFit Pro",
      options: [
        {
          key: "Overall dimensions",
          value: "60mm x 30mm x 16mm",
        },
        {
          key: "Weight",
          value: "45 g",
        },
        {
          key: "Battery",
          value: "6 hours",
        },
        {
          key: "Charging time",
          value: "1 hour",
        },
        {
          key: "Global positioning",
          value: "10 Hz GPS, GLONASS and SBAS",
        },
        {
          key: "Wireless connection",
          value: "Ultra Wideband and Bluetooth 5",
        },
        {
          key: "Wireless range",
          value: "До 300 м (СШП)",
        },
        {
          key: "Capacity",
          value: "100 athletes",
        },
        {
          key: "Accelerometer",
          value: "3D +/- 16 г. С дискретизацией 1 кГц, 100 Гц",
        },
        {
          key: "Gyroscope",
          value: "3D 2000 градусов / сек при 100 Гц",
        },
        {
          key: "Magnetometer",
          value: "3D ± 4900 мкТл при 100 Гц",
        },
        {
          key: "Compatibility",
          value: "Android 6.0+ и iOS 11.0+",
        },
      ],
    },
    // HR: {
    //   title: "NanoFit HR",
    //   options: [
    //     {
    //       key: "Габаритные размеры",
    //       value: "60 мм x 30 мм x 16 мм",
    //     },
    //     {
    //       key: "Масса",
    //       value: "40 г",
    //     },
    //     {
    //       key: "Аккумулятор",
    //       value: "6 часов",
    //     },
    //     {
    //       key: "Время зарядки",
    //       value: "1 час",
    //     },
    //     {
    //       key: "Беспроводная связь",
    //       value: "Сверхширокополосный доступ и Bluetooth 5",
    //     },
    //     {
    //       key: "Беспроводная диапазон",
    //       value: "До 300 м (СШП)",
    //     },
    //     {
    //       key: "Совместимость",
    //       value: "Android 6.0+ и iOS 11.0+ (iPhone 5S и выше)",
    //     },
    //   ],
    // },
  },
  FEEDBACK: {
    PLACEHOLDER: {
      mail: "E-mail",
      name: "Name",
      phone: "Phone number(optional)",
      textarea: "Write here your question...",
    },
    submit: "Submit",
  },
  FOOTER: {
    ogrn: "PSRN 1227700326149 dated June 6, 2022",
    inn: "ITN 7735195060",
    kpp: "TRRC 773501001",
    address:
      "Legal address: 124527, Moscow, Zelenograd, Solnechnaya alley, 6, pom. IV office 5a",
    registrationDate: "Date of registration 06.06.2022",
    mail: "info@nanofit.tech",
  },
};
