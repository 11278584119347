export default {
  LANGUAGE_TO_CHANGE: "EN",
  PAGES: {
    productTitle: "О продукте",
    advantagesTitle: "Преимущества",
    configurationsTitle: "Конфигурация",
    // configurationsTitle: "Конфигурации",
    characteristicsTitle: "Характеристики",
    feedbackTitle: "Связаться с нами",
  },
  BANNER: {
    title: "Ассистент принятия решения по обесточиванию объектов",
    subtitle:
      "на основе анализа данных от ПАК контроля и детектированию высокого энергопотребления",
  },
  PRODUCT: {
    description:
      "Основная задача проекта – разработать асистента помощи принятия решения по обесточиванию объекта на основе данных с программно-аппаратного комплекса. Оcновной и главной проблемой которая сейчас может быть решена путем применения разрабатываемого комплекса - это максимально точный контроль за потреблением, отслеживание состояний и предотвращение создания аварийных ситуаций",
    fieldTitle: [
      "Всё под контролем",
      "Всевидящее око",
      "Точность превыше всего",
      "Связь с космосом",
    ],
    fieldDescription: [
      "30 устройств мониторинга снимают данные в реальном времени с каждого игрока и следят за его физическим состоянием и перемещении по игровому полю.",
      "Устройства мониторинга передают всю информацию тренерскому составу для ее анализа и принятия решений. Тренер всегда располагает объективными данными о своей команде в целом и о каждом спортсмене в частности.",
      "Маяки для осуществления передачи данных в реальном времени на планшет с минимальной вероятностью потери событий и максимального контроля за игроками.",
      "Построение тепловой карты перемещения, анализ спринтов, бега с высокой скоростью, ускорения и других параметров, все это благодаря датчику GPS ГЛОННАС в наших устройствах.",
    ],
    card: {
      wireless: "Беспроводная передача данных",
      "flash-on": "Энергоэффективность",
      functionality: "Многофункциональность",
      "impact-resistance": "Безопасность",
    },
    additionalText:
      "Данный проект реализуется при помощи фонда содействия инновациям!",
  },
  ADVANTAGES: {
    soccer:
      "Основным преимуществом предлагаемого решения- простота установки, использования и обучения и анализа данных получаемых с устройства по средствам протокола BLE-mesh и сети интернет, благодаря чему пользователь, находясь в любой точке мира, сможет контролировать и управлять комплексом, используя свой смартфон.",
    hockey:
      "Надежность и безопасность обеспечит каждый датчик, который напрямую отдает информацию на сервер",
  },
  CONFIGURATIONS: {
    // HR: {
    //   title: "NanoFit HR",
    //   options: [
    //     "30 ЧСС устройств",
    //     "30 ремешков для ЧСС устройств",
    //     "универсальная зарядная станция",
    //     "ПО для ЧСС устройств",
    //   ],
    // },
    PRO: {
      title: "NanoFit Pro",
      options: [
        "30 устройств мониторинга",
        "30 манишек ЛИБО 30 ремешков, в зависимости от предпочтений потребителя",
        "6 маяков",
        "зарядная станция",
        "программное обеспечение",
      ],
      // options: [
      //   "30 ЧСС устройств",
      //   "30 GPS ГЛОНАСС устройств",
      //   "30 ремешков для ЧСС устройств",
      //   "30 манишек GPS ГЛОНАСС устройств ЛИБО 30 универсальных устройств с ремешками и манишками",
      //   "универсальная зарядная станция",
      //   "общее ПО",
      // ],
    },
    // GPS: {
    //   title: "NanoFit GPS",
    //   options: [
    //     "30 GPS ГЛОНАСС устройств",
    //     "30 ремешков для GPS ГЛОНАСС устройств",
    //     "универсальная зарядная станция",
    //     "ПО для GPS ГЛОНАСС устройств",
    //   ],
    // },
    SUBSCRIPTION: {
      title: "NanoFit Space",
      subtitle: "дополнительная подписка на программное обеспечение",
      options: [
        "Обширные данные в режиме реального времени (до 60 игроков)",
        "Возможность добавлять заметки во время тренировки",
        "Расширенный вид анализа для углубленного сравнения данных выборки",
        "Тепловая карта для анализа местоположения",
        "Мощный анализ и сравнение тренировки",
        "Удобные настройки команды и игроков через профили видов спорта",
        "Легкий доступ и совместное использование данных тренерским штабом по нескольким командам",
        "Положение игрока на тепловой карте или линейном графике",
      ],
    },
  },
  CHARACTERISTICS: {
    GPS: {
      title: "NanoFit Pro",
      options: [
        {
          key: "Габаритные размеры",
          value: "60 мм x 30 мм x 16 мм",
        },
        {
          key: "Масса",
          value: "45 г",
        },
        {
          key: "Аккумулятор",
          value: "6 часов",
        },
        {
          key: "Время зарядки",
          value: "1 час",
        },
        {
          key: "Глобальное позиционирование",
          value: "10 Гц GPS, ГЛОНАСС и SBAS",
        },
        {
          key: "Беспроводная связь",
          value: "Сверхширокополосный доступ и Bluetooth 5",
        },
        {
          key: "Беспроводная диапазон",
          value: "До 300 м (СШП)",
        },
        {
          key: "Вместимость",
          value: "100 спортсменов",
        },
        {
          key: "Акселерометр",
          value: "3D +/- 16 г. С дискретизацией 1 кГц, 100 Гц",
        },
        {
          key: "Гироскоп",
          value: "3D 2000 градусов / сек при 100 Гц",
        },
        {
          key: "Магнитометр",
          value: "3D ± 4900 мкТл при 100 Гц",
        },
        {
          key: "Совместимость",
          value: "Android 6.0+ и iOS 11.0+",
        },
      ],
    },
    // HR: {
    //   title: "NanoFit HR",
    //   options: [
    //     {
    //       key: "Габаритные размеры",
    //       value: "60 мм x 30 мм x 16 мм",
    //     },
    //     {
    //       key: "Масса",
    //       value: "40 г",
    //     },
    //     {
    //       key: "Аккумулятор",
    //       value: "6 часов",
    //     },
    //     {
    //       key: "Время зарядки",
    //       value: "1 час",
    //     },
    //     {
    //       key: "Беспроводная связь",
    //       value: "Сверхширокополосный доступ и Bluetooth 5",
    //     },
    //     {
    //       key: "Беспроводная диапазон",
    //       value: "До 300 м (СШП)",
    //     },
    //     {
    //       key: "Совместимость",
    //       value: "Android 6.0+ и iOS 11.0+ (iPhone 5S и выше)",
    //     },
    //   ],
    // },
  },
  FEEDBACK: {
    PLACEHOLDER: {
      mail: "E-mail",
      name: "Имя",
      phone: "Телефон(по желанию)",
      textarea: "Напишите здесь свой вопрос...",
    },
    submit: "Отправить",
  },
  FOOTER: {
    ogrn: "ОГРН 1227700326149 от 6 июня 2022 г.",
    inn: "ИНН 7735195060",
    kpp: "КПП 773501001",
    address:
      "Юридический адрес: 124527, город Москва, г Зеленоград, Солнечная аллея, д. 6, помещ. IV офис 5а",
    registrationDate: "Дата регистрации 06.06.2022",
    mail: "fasie.ru",
  },
};
