<template>
  <div
    class="card product__card fadeDown"
    :style="`transition-delay: ${0.08 * num}s`"
  >
    <Icon size="big" :type="type"></Icon>
    <p class="product__card-subtitle">{{ getText }}</p>
  </div>
</template>

<script setup>
import Icon from "@components/Icon.vue";
import useTranslate from "@hooks/useTranslate";
import { computed } from "vue";

const props = defineProps({
  num: {
    type: Number,
  },
  type: {
    type: String,
    required: true,
  },
});

const translate = useTranslate();

const getText = computed(() =>
  translate.value.PRODUCT.card[props.type].toUpperCase()
);
</script>

<style lang="scss" scoped>
.product__card {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.product__card-subtitle {
  text-align: center;
  font-family: "Roboto";
  font-size: 20px;

  color: #ffffff;

  @media (max-width: 1024px), (max-height: 1024px) {
    font-size: 16px;
  }
}
</style>
