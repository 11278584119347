<template>
  <section class="advantages padding-top" id="advantages">
    <Title>{{ translate.PAGES.advantagesTitle }}</Title>
    <AdvantagesSlider></AdvantagesSlider>
  </section>
</template>

<script setup>
import Title from "@components/Title.vue";
import AdvantagesSlider from "@components/advantages/AdvantagesSlider.vue";
import useTranslate from "@hooks/useTranslate";

const translate = useTranslate();
</script>

<style lang="scss" scoped></style>
