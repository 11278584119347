<template>
  <span class="icon" :class="iconClass"></span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: null,
    required: true,
  },
});

const iconSizeClass = computed(() => (props.size ? `icon--${props.size}` : ""));
const iconTypeClass = computed(() => (props.type ? `icon--${props.type}` : ""));

const iconClass = computed(
  () => `${iconSizeClass.value} ${iconTypeClass.value}`
);
</script>

<style lang="scss" scoped>
@import "../colors.scss";

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  background-color: currentColor;
  color: $mainColor;

  &--small {
    width: 16px;
    height: 16px;
  }

  &--big {
    width: 120px;
    height: 120px;
    color: $lightenColor;

    @media (max-width: 768px), (max-height: 768px) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: 425px), (max-height: 425px) {
      width: 80px;
      height: 80px;
    }
  }

  &--wireless {
    -webkit-mask-image: url("@assets/icons/wireless.svg");
    mask-image: url("@assets/icons/wireless.svg");
  }

  &--portable {
    -webkit-mask-image: url("@assets/icons/portable.svg");
    mask-image: url("@assets/icons/portable.svg");
  }

  &--functionality {
    -webkit-mask-image: url("@assets/icons/functionality.svg");
    mask-image: url("@assets/icons/functionality.svg");
  }

  &--impact-resistance {
    -webkit-mask-image: url("@assets/icons/impact-resistance.svg");
    mask-image: url("@assets/icons/impact-resistance.svg");
  }

  &--moisture-resistance {
    -webkit-mask-image: url("@assets/icons/moisture-resistance.svg");
    mask-image: url("@assets/icons/moisture-resistance.svg");
  }

  &--temperature-resistance {
    -webkit-mask-image: url("@assets/icons/temperature-resistance.svg");
    mask-image: url("@assets/icons/temperature-resistance.svg");
  }

  &--check {
    -webkit-mask-image: url("@assets/icons/check.svg");
    mask-image: url("@assets/icons/check.svg");
  }

  &--flash-on {
    -webkit-mask-image: url("@assets/icons/flash_on.svg");
    mask-image: url("@assets/icons/flash_on.svg");
  }
}
</style>
