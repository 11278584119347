<template>
  <div class="advantages__slider margin-top fadeIn">
    <Swiper
      :slides-per-view="1"
      :space-between="10"
      :modules="[Autoplay, Navigation]"
      :loop="true"
      :autoplay="{
        delay: 7000,
        disableOnInteraction: false,
      }"
      :navigation="true"
    >
      <SwiperSlide v-for="(slider, index) in sliders" :key="index">
        <div class="advantages__slide" :class="'advantages__slide--' + slider">
          <div class="advantages__slide-container">
            <p class="advantages__slide-text container">
              {{ getSlideText(slider) }}
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import useTranslate from "@hooks/useTranslate";

const translate = useTranslate();
const sliders = ["soccer", "hockey"];

const getSlideText = (slide) => translate.value.ADVANTAGES[slide];
</script>

<style lang="scss" scoped>
@import "../../colors.scss";
.advantages__slider {
  width: 100%;
  height: 60vh;
  min-height: 280px;

  @media (max-width: 768px), (max-height: 768px) {
    height: calc(100vh - 150px);
  }

  & .swiper {
    height: 100%;
  }
}

.advantages__slide {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;

  &--soccer {
    background-image: url("@assets/electro-change.jpg");
  }

  &--hockey {
    background-image: url("@assets/poverka.jpg");
  }

  &--basketball {
    background-position: center;
    background-image: url("@assets/basketball.png");
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    height: 25%;
    margin-bottom: 5%;
    width: 100%;

    @media (max-width: 1024px), (max-height: 1024px) {
      height: 30%;
    }

    @media (max-width: 768px), (max-height: 768px) {
      height: 35%;
    }

    @media (max-width: 425px), (max-height: 425px) {
      height: 40%;
      margin-bottom: 0;
    }
  }

  &-text {
    font-family: "Roboto";
    font-size: 28px;

    color: #eaeaea;

    @media (max-width: 1024px), (max-height: 1024px) {
      font-size: 22px;
    }

    @media (max-width: 768px), (max-height: 768px) {
      font-size: 20px;
    }

    @media (max-width: 425px), (max-height: 425px) {
      font-size: 16px;
    }
  }
}
</style>
