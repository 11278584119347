<template>
  <header class="header">
    <div class="container header__container slideDown t-animated">
      <RouterLink @click="isShown = false" :to="{ hash: '' }">
        <!-- <img
          class="logo"
          data-t-show="1"
          src="@assets/fasie-logo.png"
          alt="logo"
        /> -->
      </RouterLink>

      <div class="header__menu" :class="menuClass">
        <nav class="header__nav">
          <ul>
            <RouterLink @click="isShown = false" :to="{ hash: '#product' }">
              <li class="header__nav-item">
                {{ translate.PAGES.productTitle }}
              </li>
            </RouterLink>
            <RouterLink @click="isShown = false" :to="{ hash: '#advantages' }">
              <li class="header__nav-item">
                {{ translate.PAGES.advantagesTitle }}
              </li>
            </RouterLink>
            <!-- <RouterLink
              @click="isShown = false"
              :to="{ hash: '#configurations' }"
            >
              <li class="header__nav-item">
                {{ translate.PAGES.configurationsTitle }}
              </li>
            </RouterLink> -->
            <!-- <RouterLink
              @click="isShown = false"
              :to="{ hash: '#characteristics' }"
            >
              <li class="header__nav-item">
                {{ translate.PAGES.characteristicsTitle }}
              </li>
            </RouterLink> -->
          </ul>
        </nav>

        <!-- <div class="header__actions">
          <div class="header__button header__button--feedback">
            <RouterLink @click="isShown = false" :to="{ hash: '#feedback' }">
              <Button>{{ translate.PAGES.feedbackTitle }}</Button>
            </RouterLink>
          </div>

          <div class="header__button header__button--language">
            <Button @click="changeLanguage" type="filled">{{
              translate.LANGUAGE_TO_CHANGE
            }}</Button>
          </div>
        </div> -->
      </div>

      <Hamburger v-model="isShown"></Hamburger>
    </div>
  </header>
</template>

<script setup>
import { computed, ref } from "vue";
import useTranslate from "@hooks/useTranslate";
import { useStore } from "vuex";
import { RouterLink } from "vue-router";

import Button from "@components/Button.vue";
import Hamburger from "@components/Hamburger.vue";

const translate = useTranslate();
const store = useStore();

const isShown = ref(false);

const menuClass = computed(() => (isShown.value ? "header__menu--open" : ""));

const changeLanguage = () => {
  store.dispatch("changeLanguage");
};
</script>

<style lang="scss">
@import "../colors.scss";
@import "../consts.scss";

.header {
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  background-color: black;
  height: 90px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: ($headerBreakpoint - 1)) {
      & {
        position: absolute;
        flex-direction: column-reverse;
        justify-content: center;
        min-height: calc(100vh - 90px);
        width: 100%;
        top: 90px;
        left: 0;
        background-color: black;
        margin-left: -100%;
        transition: margin-left 0.3s;
        padding: 20px 0;
        gap: 20px;
      }

      &--open {
        margin-left: 0;
      }
    }
  }

  &__nav {
    @media (max-width: ($headerBreakpoint - 1)) {
      & {
        width: 100%;
      }
    }

    & ul {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 25px;

      & a {
        text-decoration: none;
      }

      @media (max-width: ($headerBreakpoint - 1)) {
        & {
          width: 100%;
          flex-direction: column;
        }

        @media (max-height: 404px) {
          & {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
          }
        }
      }
    }

    &-item {
      position: relative;
      color: white;
      text-align: center;
      cursor: pointer;

      @media (max-width: ($headerBreakpoint - 1)) {
        & {
          font-size: 22px;
          padding: 5px 0;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: 4px;
        width: 50px;
        background-color: $mainColor;
        border-radius: 4px;
        opacity: 0;
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% + 4px);
        transition: opacity 0.3s;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.logo {
  height: 60px;
  @media (max-width: $logoBreakpoint) {
    & {
      height: 48px;
    }
  }
}
</style>
